<template>
  <div
    id="rewards-calculator"
    class="rwd-calc"
    data-ui="rewards-calculator"
    :style="cssVars"
    role="group"
    aria-labelledby="rewards-calculator-heading"
  >
    <div class="rwd-calc__col-1">
      <cdr-text
        id="rewards-calculator-heading"
        tag="h2"
        class="rwd-calc__heading"
      >
        <span v-html="heading" />
      </cdr-text>
      <div class="rwd-calc__image">
        <img
          class="rwd-calc__stamp"
          alt="an illustration that says \&quot;earn rewards\&quot;"
          src="https://www.rei.com/assets/credit-card/capital-one/rewardsbadge/live.svg"
        >
        <cdr-img
          class="rwd-calc__main-image"
          alt="a cyclist riding to a yoga class"
          src="https://www.rei.com/assets/credit-card/capital-one/calculator-biker/live.jpg"
        />
      </div>
    </div>
    <div class="rwd-calc__col-2">
      <div
        v-for="{ model, handler, bonusTemplate } in rangeSliderModels"
        :key="model.id"
        class="rwd-calc__range-slider-module"
      >
        <range-slider
          v-bind="model"
          @input="handler"
        />
        <div class="rwd-calc__aside">
          <img
            :src="model.src"
            :alt="model.alt"
            :class="`rwd-calc__icon rwd-calc__icon--${model.id}`"
          >
          <span
            class="rwd-calc__bonus"
            data-ui="rewards-calc-bonus"
          >
            {{ bonusTemplate }}
          </span>
        </div>
      </div>
      <div class="rwd-calc__rewards-outcome">
        <span
          class="rwd-calc__grand-total"
          data-ui="rewards-calc-grand-total"
        >
          = <span>${{ totalBonus }} in card rewards</span><sup>3</sup>
          <aria-announcer
            class="sr-only"
            data-ui="aria-live-text"
            :message="ariaLiveText"
          />
        </span>
        <span
          class="rwd-calc__disclaimer"
          v-html="rewardDisclaimer"
        />
      </div>
      <div class="rwd-calc__member-reward-mention">
        <div class="rwd-calc__member-reward-title">
          <h3>
            <span class="sr-only">plus</span>
          </h3>
          <img
            src="https://www.rei.com/assets/credit-card/capital-one/plus/live.svg"
            alt="plus"
          >
        </div>
        <span
          class="rwd-calc__member-reward-content"
          v-html="memberRewardMention"
        />
      </div>
      <div class="rwd-calc__faq">
        <cdr-button
          tag="a"
          :href="cta?.href"
          modifier="secondary"
        >
          {{ cta?.text }}
        </cdr-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import AriaAnnouncer from '@rei/aria-announcer';
import { CdrButton, CdrImg, CdrText } from '@rei/cedar';
import RangeSlider from '../../../common/components/range-slider/RangeSlider.vue';
import { metricsLink } from '../../../utils/analytics';

const props = defineProps({
  allCoopPurchases: {
    type: Object,
    required: true,
  },
  cta: {
    type: Object,
    required: true,
  },
  rewardDisclaimer: {
    type: String,
    required: true,
  },
  everydayPurchases: {
    type: Object,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  memberRewardMention: {
    type: String,
    required: true,
  },
  rewardsOutput: {
    type: String,
    required: true,
  },
});
const everydayPurchasesValue = ref(props.everydayPurchases.initialValue);
const allCoopPurchasesValue = ref(props.allCoopPurchases.initialValue);
/* eslint-disable-next-line */
const everydayPurchasesBonus = ref(props.everydayPurchases.initialValue * props.everydayPurchases.percent);
/* eslint-disable-next-line */
const allCoopPurchasesBonus = ref(props.allCoopPurchases.initialValue * props.allCoopPurchases.percent);

const activeSlider = ref('');
const totalBonus = computed(() => everydayPurchasesBonus.value + allCoopPurchasesBonus.value);
const bonusMap = {
  'everyday-purchases': everydayPurchasesBonus,
  'all-co-op-purchases': allCoopPurchasesBonus,
};

const dollars = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

const cssVars = computed(() => ({
  '--rewards-calculator-graphic-circle': 'url(https://www.rei.com/assets/credit-card/capital-one/circle/live.svg)',
  '--rewards-calculator-graphic-plus': 'url(https://www.rei.com/assets/credit-card/capital-one/plus/live.svg)',
}));

/*
 * Per feedback from A11y team, groups updates from individual and aggregrate rewards calculations
 * into one message
 */
const ariaLiveText = computed(() => {
  if (!activeSlider.value) return '';
  const sliderName = activeSlider.value.replace('-', ' ');
  const bonusInt = bonusMap[activeSlider.value].value;
  const bonusCurr = dollars.format(bonusInt);
  const totalCurr = dollars.format(totalBonus.value);
  // "Everyday purchases awards are now $30, total rewards are now $50."
  return `${sliderName} awards are now ${bonusCurr}, total rewards are now ${totalCurr}`;
});

const getThrottledLinkClick = () => {
  let debounceTimer;
  return (id) => {
    // cheap and easy debounce
    // https://webdesign.tutsplus.com/tutorials/javascript-debounce-and-throttle--cms-36783
    const linkName = `calculator_${id}`;
    clearTimeout(debounceTimer);
    /* c8 ignore start */
    debounceTimer = setTimeout(() => {
      metricsLink({ linkName });
    }, 600);
    /* c8 ignore end */
  };
};
const throttledLinkClick = getThrottledLinkClick();

/*
 * Forces rewards estimates to round to whole numbers
 */
const formatBonus = (val, perc) => {
  const rounded = Math.round(val * perc);
  return rounded;
};

const handleInput = (valueText, id) => {
  const value = parseInt(valueText, 10);
  activeSlider.value = id;
  throttledLinkClick(id);
  if (id === 'everyday-purchases') {
    everydayPurchasesValue.value = value;
    everydayPurchasesBonus.value = formatBonus(value, props?.everydayPurchases?.percent);
  } else {
    allCoopPurchasesValue.value = value;
    allCoopPurchasesBonus.value = formatBonus(value, props?.allCoopPurchases?.percent);
  }
};

const rangeSliderModels = computed(() => {
  const everyday = {
    model: {
      ...props.everydayPurchases,
      valueTextTemplate: '$#',
      minMaxTextTemplate: '$#',
      value: everydayPurchasesValue.value,
    },
    handler: (val) => handleInput(val, 'everyday-purchases'),
    bonusTemplate: `+$${everydayPurchasesBonus.value}`,
  };
  const allCoopPurchases = {
    model: {
      ...props.allCoopPurchases,
      valueTextTemplate: '$#',
      minMaxTextTemplate: '$#',
      value: allCoopPurchasesValue.value,
    },
    handler: (val) => handleInput(val, 'all-co-op-purchases'),
    bonusTemplate: `+$${allCoopPurchasesBonus.value}`,
  };

  return [everyday, allCoopPurchases]
    // eslint-disable-next-line
    .sort((a, b) => a.model.order - b.model.order);
});
</script>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
@import '@rei/cdr-component-variables/dist/scss/cedar-component-variables.scss';

.rwd-calc {
  --aside-width: 64px;

  sup {
    font-size: 0.8rem;
    font-weight: 600;
  }
  @include cdr-md-mq-up {
    --aside-width: 85px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 3.7rem;
  }
}

.rwd-calc__heading {
  @include cdr-text-utility-serif-600;
  color: $cdr-color-text-brand;
  margin-bottom: $cdr-space-one-x;
  @include cdr-md-mq-up {
    @include cdr-text-heading-display-800;
    margin-bottom: 8rem;
  }
}

.rwd-calc__image {
  display: none;
  @include cdr-md-mq-up {
    position: relative;
    display: block;
  }
}

.rwd-calc__stamp {
  position: absolute;
  top: -9.95%;
  right: 9.5%;
  max-width: 150px;
}

.rwd-calc__main-image {
  border-top-right-radius: 0.8rem; // 2x $cdr-radius-softer
  border-top-left-radius: 0.8rem; // 2x $cdr-radius-softer
}

.rwd-calc__col-2 {
  @include cdr-md-mq-up {
    padding-bottom: $cdr-space-one-and-a-half-x;
  }
}

.range-slider__label {
  @include cdr-text-heading-sans-400;
  color: $cdr-color-text-brand;
  @include cdr-md-mq-up {
    @include cdr-text-heading-sans-500;
  }
}

.range-slider__helper-text {
  color: $cdr-color-text-primary;
  span {
    font-weight: $cdr-text-utility-sans-strong-100-weight;
  }
  @include cdr-md-mq-up {
    @include cdr-text-utility-sans-300;
    padding-right: $cdr-space-one-and-a-half-x;
  }
}

.rwd-calc__range-slider-module {
  display: grid;
  grid-template-columns: 1fr var(--aside-width);
  grid-gap: $cdr-space-one-x;
  position: relative;
  padding-bottom: $cdr-space-one-x;
  margin-bottom: $cdr-space-one-x;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $cdr-color-border-primary;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.rwd-calc__rewards-outcome {
  text-align: center;
  margin-bottom: $cdr-space-one-x;
  @include cdr-md-mq-up {
    text-align: right;
    max-width: 350px;
    margin-left: auto;
  }
}

.rwd-calc__grand-total {
  display: inline-block;
  padding: $cdr-space-half-x $cdr-space-one-and-a-half-x;
  background: center/100% no-repeat;
  background-image: var(--rewards-calculator-graphic-circle);
  color: $cdr-color-text-brand;
  @include cdr-text-heading-sans-400;
  @include cdr-md-mq-up {
    @include cdr-text-heading-sans-500;
  }
}

.rwd-calc__disclaimer {
  display: block;
  @include cdr-text-utility-sans-100;
}

.rwd-calc__aside {
  width: var(--aside-width);
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr 46px;
  @include cdr-md-mq-up {
    grid-template-rows: 1fr 48px;
  }
}

.rwd-calc__icon {
  width: 100%;
}

.rwd-calc__icon--everyday-purchases {
  @include cdr-md-mq-up {
    max-width: 85%;
  }
}

.rwd-calc__bonus {
  color: $cdr-color-text-brand;
  @include cdr-text-utility-sans-300;
  font-weight: $cdr-text-heading-sans-300-weight;
  @include cdr-md-mq-up {
    justify-self: end;
  }
}

.rwd-calc__member-reward-mention {
  border: 1px solid $cdr-color-border-primary;
  border-radius: $cdr-radius-softer;
  padding: $cdr-space-half-x $cdr-space-three-quarter-x;
  margin-bottom: $cdr-space-one-x;
  @include cdr-md-mq-up {
    margin-bottom: $cdr-space-one-and-a-half-x;
  }

  .rwd-calc__member-reward-title {
    max-width: 44px;
  }
  h3 { margin: 0; }
}

.rwd-calc__member-reward-content {
  @include cdr-text-utility-sans-100;
  display: inline-block;
  span {
    font-weight: $cdr-text-utility-sans-strong-100-weight;
  }
}

.rwd-calc__faq {
  text-align: center;
  @include cdr-sm-mq-down {
    button {
      @include cdr-button-base-text-mixin-small;
    }
  }
  @include cdr-md-mq-up {
    text-align: left;
  }
}
</style>
